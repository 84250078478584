import React, { useState } from 'react';
import { Link } from 'gatsby';
import styles from './article-card.module.scss';
import Img from 'gatsby-image';


interface Props {
    article: any
};

const ArticleCard = ({article}: Props) => {
  return (
    <Link to={`/a/${article.slug}`}>
      <div className={styles.articleCard}>
        <div>
          <Img
            className={styles.img}
            alt={article.heroImage.title}
            fluid={article.heroImage.fluid}
          />
          <div className={styles.articleTitle}>{article.title}</div>
        </div>
        <div>{article?.summary}</div>
      </div>
    </Link>
  )
}

export default ArticleCard
