import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import get from 'lodash/get';
import Layout from '../components/layout/layout';
import ArticleCard from '../components/article-card/article-card';
import styles from './index.module.scss';

interface Props {
  data: any;
  location: Location;
}

const TNIndex = ({data}: Props) => {
    const articles = get(data, 'allContentfulArticle.nodes');
    const siteTitle = get(data, 'site.siteMetadata.title');

    return (
      <Layout>
        <Helmet title={siteTitle} />
        <h2>Latest Articles</h2>
        <div className={styles.latestArticles}>
          { articles.map((article: any) => {
            return(
              <ArticleCard article={article} />
            );
          })}
        </div>
      </Layout>
    );
};

export default TNIndex;

export const pageQuery = graphql`
  query allLatestArticles {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulArticle(limit: 10, sort: {order: ASC, fields: publishedAt}) {
      nodes {
        title
        publishedAt(formatString: "MMMM Do, YYYY")
        summary
        slug
        heroImage {
          title
          fluid(maxWidth: 1180, background: "rgb:000000", quality: 100) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`
